import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 300 : 0,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? 300 : 0,
    opacity: 0,
  }),
};

const SecondSlide = ({ currentIndex, direction, paginate, projects, language }) => {
  if (!projects || projects.length === 0) {
    return <div>No projects available.</div>;
  }

  const currentProject = projects[currentIndex];
  if (!currentProject) {
    return <div>Invalid project index.</div>;
  }

  return (
    <div className="second-slide">
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentIndex}
          className="second-slide-content"
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            y: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset }) => {
            if (offset.x > 100) paginate(-1); // Swipe right to go previous
            if (offset.x < -100) paginate(1); // Swipe left to go next
          }}
        >
          <div
            className="card text-white bg-secondary bg-opacity-75"
            style={{
              width: "80%",
              maxWidth: "600px",
              margin: "0 auto",
              padding: "20px",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            {/* Project Image */}
            <div
              style={{
                height: "200px",
                width: "100%",
                backgroundImage: `url(${currentProject?.titleimage || "default-image.jpg"})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px 10px 0 0",
              }}
            />

            {/* Project Info */}
            <div className="p-4">
              <h5 className="card-title text-center">
                {currentProject?.title[language] || "Default Title"}
              </h5>
              <p className="card-text text-center">
                {currentProject?.description[language] || "No description available."}
              </p>
              {currentProject?.github && (
                <div className="text-center">
                  <a
                    href={currentProject.github}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on GitHub
                  </a>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default SecondSlide;
