import React, { useRef, useState, useEffect } from "react";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import "./App.css";
import { initializeCanvas } from "./bg.js";
import ProjectSlider from './ProjectSlider';
import SecondSlider from './SecondSlider';
import 'bootstrap/dist/css/bootstrap.min.css';
import LanguageSwitchButton from "./LanguageSwitch";

import projects from './projects.json';
function App() {
  const containerRef = useRef(null);
  const tabRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const [maxZipperY, setMaxZipperY] = useState(0);
  const [isTabFlipped, setIsTabFlipped] = useState(false);
  const [pagination, setPagination] = useState({ currentIndex: 0, direction: 0 });
  const [language, setLanguage] = useState("fr");
  const handleHeight = 50;
  const initialZipperY = -20; // Start the zipper at the top
  const zipPosRatio = 0.9; // Adjusted zipper position ratio

  const [zipPos, setZipPos] = useState(containerWidth * zipPosRatio);
  const [halfWidth, setHalfWidth] = useState(zipPos);

  const zipperY = useMotionValue(initialZipperY);
  const rotation = useMotionValue(0);


  const paginate = (newDirection) => {
    const newIndex =
      (pagination.currentIndex + newDirection + projects.length) % projects.length;
    setPagination({ currentIndex: newIndex, direction: newDirection });
  };
  

  useEffect(() => {
    // Initialize the canvas background
    const canvas = document.getElementById("backgroundCanvas");
    initializeCanvas(canvas);

    // Set dimensions after component mounts
    const handleResize = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setContainerHeight(rect.height);
        setContainerWidth(rect.width);
        setMaxZipperY(rect.height - handleHeight + 20);

        // Dynamically update zipPos and halfWidth
        const newZipPos = rect.width * zipPosRatio;
        setZipPos(newZipPos);
        setHalfWidth(newZipPos);
      }
    };

    handleResize(); // Initialize on mount

    window.addEventListener("resize", handleResize); // Listen for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on unmount
    };
  }, [handleHeight]);

  // Maximum border-radius for the top corners
  const maxBorderRadius = maxZipperY / 2;

  // Animate border-radius
  const leftBorderRadius = useTransform(
    zipperY,
    [initialZipperY, maxZipperY / 2, maxZipperY],
    [0, maxBorderRadius, maxBorderRadius]
  );
  const rightBorderRadius = leftBorderRadius; // Same as left for symmetry

  // Content movement
  const leftContentX = useTransform(
    zipperY,
    [initialZipperY, maxZipperY],
    [0, -halfWidth]
  );
  const rightContentX = useTransform(
    zipperY,
    [initialZipperY, maxZipperY],
    [0, halfWidth]
  );
  const contentY = useTransform(zipperY, [initialZipperY, maxZipperY], [0, 50]);

  // Halves move down after midpoint
  const halvesY = useTransform(
    zipperY,
    [maxZipperY / 2, maxZipperY],
    [0, containerHeight]
  );

  // Halves flatten after midpoint
  const halvesScaleY = useTransform(
    zipperY,
    [maxZipperY / 2, maxZipperY],
    [1, 0]
  );
  //Handler for when the drag
  const handleDrag = (event, info) => {
    if (!tabRef.current) return;

    const tabRect = tabRef.current.getBoundingClientRect();
    const tabCenterY = tabRect.top + tabRect.height / 2;
    const tabCenterX = tabRect.left + tabRect.width / 2;

    // Calculate the mouse position relative to the tab's center
    const deltaY = info.point.y - tabCenterY;
    const deltaX = info.point.x - tabCenterX;

    // Calculate the distance from the tab center to the mouse pointer
    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);

    // Threshold to stabilize rotation when the mouse is too close
    const minDistance = tabRect.height / 3; // Half the height of the tab

    // Calculate the angle between the tab center and mouse pointer
    let angle;

    if (distance < minDistance) {
      angle = rotation.get();
    } else {
      angle =
        Math.atan2(info.point.y - tabCenterY, info.point.x - tabCenterX) *
        (180 / Math.PI);

      // Offset the angle to make "down" 0 degrees
      angle = (angle - 90) % 360; // Ensure angle is within 0 to 360 degrees

      if (isTabFlipped) {
        // Adjust for flipped state (add 180° offset)
        angle = angle % 360;
      }
    }
    // Set the rotation to the adjusted angle
    rotation.set(angle);
  };
  // Handler for when the drag ends
  const handleDragEnd = () => {
    const currentY = zipperY.get();
    if (currentY >= maxZipperY - handleHeight / 2) {
      setIsTabFlipped(true); // Flip the tab up
      rotation.set(180);
    } else if (currentY < maxZipperY / 2) {
      animate(zipperY, initialZipperY, {
        type: "spring",
        stiffness: 300,
        damping: 40,
      });
      // Swing the tab back
      if (!isTabFlipped) {
        animate(rotation, 0, {
          type: "spring",
          stiffness: 100,
          damping: 10,
        });
      }
      rotation.set(0);
      setIsTabFlipped(false); // Reset the tab
    } else {
      animate(zipperY, maxZipperY, {
        type: "spring",
        stiffness: 300,
        damping: 40,
        onComplete: () => {
          rotation.set(180);
          setIsTabFlipped(true); // Flip the tab up after animation completes
        },
      });
    }
  };

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "fr" ? "en" : "fr"));
  };
  
  return (
      
    <div ref={containerRef} className="container position-relative ms-0 ps-0">
      <div className="bg-wrapper">
        <canvas id="backgroundCanvas"></canvas>
      </div>
      {/* Second Page */}
      <div className="second-page">
        <motion.div
        className="motion-modal"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        >
          <motion.div
            className="motion-modal"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <SecondSlider
              currentIndex={pagination.currentIndex}
              direction={pagination.direction}
              paginate={paginate}
              projects={projects}
              language={language}
            />
          </motion.div>

        </motion.div>
      </div>

      {/* Left Half */}
      <motion.div
        className="first-page-left"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = offset.x * velocity.x;
          if (swipe < -1000) {
            paginate(1);
          } else if (swipe > 1000) {
            paginate(-1);
          }
        }}
        style={{
          backgroundImage: `url(${projects[pagination.currentIndex].image})`,
          backgroundSize: 'cover',
          backgroundPosition: "center",
          position: "absolute",
          left: 0, // Position so that it ends at zipPos
          width: "90vw",
          right: "10vw",
          y: halvesY,
          scaleY: halvesScaleY,
          originY: 0, // Scale from top
          borderTopRightRadius: leftBorderRadius,
        }}
      >
        <motion.div
          className="content"
          style={{
            x: leftContentX,
            y: contentY,
          }}
        >
        <ProjectSlider
          currentIndex={pagination.currentIndex}
          direction={pagination.direction}
          paginate={paginate}
          projects={projects}
          language={language}
        />

        </motion.div>
      </motion.div>

      {/* Right Half */}
      <motion.div
        className="first-page-right"
        style={{
          backgroundColor: `black`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: "absolute",
          width: "90vw",
          left: "90vw",
          y: halvesY,
          scaleY: halvesScaleY,
          originY: 0, // Scale from top
          borderTopLeftRadius: rightBorderRadius,
        }}
      >
        <motion.div
          className="content"
          style={{
            x: rightContentX,
            y: contentY,
          }}
        >
        </motion.div>
      </motion.div>

      {/* Zipper Handle */}
      <motion.div
        className="zipper-handle"
        style={{
          x: "-50%", // Center the handle horizontally
          y: zipperY, // Animate the vertical position
          left: "90vw", // Position at the 90% mark
          top: 0, // Start at the top
        }}
        drag="y"
        dragConstraints={{ top: 0, bottom: maxZipperY }}
        dragElastic={0}
        onDrag={handleDrag} // Handle rotation during drag
        onDragEnd={handleDragEnd} // Reset after drag ends
      >
        {/* Pull Tab */}
        <motion.div
          ref={tabRef}
          className={`metal zipper-tab ${isTabFlipped ? "flipped" : ""}`}
          style={{
            rotate: rotation, // Apply the rotation dynamically
          }}
        >
          <span className="depth" title={isTabFlipped ? "Frontend" : "Backend"}>
            {isTabFlipped ? "Frontend" : "Backend"}
          </span>
        </motion.div>
        <div className="hook"></div>
      </motion.div>



    {/* Navigation Buttons */}
    <div className="navButtonDiv position-relative" style={{
      width:"100vw",
      height:"100vh",
    }}>


    <div className="position-absolute start-0 top-0"
    style={{
      zIndex:"9999",
    }}
    >
      {/* Language Switch Button */}
      <LanguageSwitchButton toggleLanguage={toggleLanguage} language={language} />
    </div>

    {/* Left Arrow */}
    <button
      className="btn bg-transparent position-absolute top-50 start-0 "
      onClick={() => paginate(-1)}
      style={{
        zIndex:"9999",
        pointerEvents: "auto", // Allow interaction for the button
      }}
    >
      <svg
        fill="#FFFFFF"
        height="70px"
        width="60px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 330 330"
        style={{ transform: "rotate(180deg)", opacity: 0.5 }}
        >
        <path
         stroke="black" strokeWidth="10" strokeLinecap="round"
          d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
          c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
          C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
          C255,161.018,253.42,157.202,250.606,154.389z"
        />
      </svg>
    </button>

    {/* Right Arrow */}
    <button
      className="btn bg-transparent position-absolute top-50 end-0"
      onClick={() => paginate(1)}
      style={{
        zIndex:"9999",
        pointerEvents: "auto", // Allow interaction for the button
      }}
    >
      <svg
        fill="#FFFFFF"
        height="70px"
        width="60px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 330 330"
        style={{ opacity: 0.5 }}
      >
        <path
          stroke="black" strokeWidth="10" strokeLinecap="round"
          d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
          c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
          C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
          C255,161.018,253.42,157.202,250.606,154.389z"
        />
      </svg>
    </button>
  </div>
</div>
  );
}

export default App;
